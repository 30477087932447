@import "../../assets/styles/variables";

.MainMenu {
    display: flex;
    flex-direction: column;
    padding-top: 0;
    background: #051316 url("../../assets/images/ptc-bg.jpg") repeat-x center top;

    > * {
        flex-basis: 100%;
    }

    :global {
        .game a {
            border: 1px solid $white;
            border-radius: 4px;

            @media (min-width: $screen-lg-min) {
                padding: 7px 22px;
            }
        }

        .placeholder {
            font-size: 18px;
            border-radius: 4px;
        }
    }
}

.Logo {
    margin-top: 18px;
    margin-bottom: 5px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
